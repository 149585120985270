import { Route, Switch, useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Layout from './Layout';
import Charts from './Charts/Charts';
import Chart from './Chart/Chart';
import Login from './Login';
import Logout from './Logout';
import GotoChart from './GotoChart/GotoChart';

import OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';



// import { BrowserRouter as Router, Route, useHistory } from "react-router-dom";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
// import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import oktaConfig from "./OKTA.env.js";
import { useContext, useEffect } from 'react';
import { getAuth, signInWithCustomToken } from '@firebase/auth';
import OktaLogin from './OktaLogin';
import { AppStateContext } from './AppState';
import OktaLoginCallback from './OktaLoginCallback';


// import { LandingPage } from "./LandingPage";
// import { Dashboard } from "./Dashboard";
// import { Header } from "./Header";
// const CLIENT_ID = process.env.CLIENT_ID;
// const CALLBACK_PATH = process.env.CALLBACK_PATH;
// const ISSUER = process.env.ISSUER;
// const HOST = process.env.HOST;
// const REDIRECT_URI = `http://${HOST}${CALLBACK_PATH}`;
// const SCOPES = process.env.SCOPES;

// if (!SCOPES || !CLIENT_ID || !CALLBACK_PATH || !ISSUER || !HOST) {
//   throw new Error("All environmental variables must be set");
// }

// const config = {
//   issuer: ISSUER,
//   clientId: CLIENT_ID,
//   redirectUri: REDIRECT_URI,
//   scopes: SCOPES.split(/\s+/),
// };

const oktaAuth = new OktaAuth(oktaConfig);

// const App = () => {
//   const history = useHistory();
//   const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
//     history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
//   };
//   return (
//     <Router>
//       <Security restoreOriginalUri={restoreOriginalUri} oktaAuth={oktaAuth}>
//         <Header />
//         <Route path="/" exact={true} component={LandingPage} />
//         <SecureRoute path="/dashboard" exact={true} component={Dashboard} />
//       </Security>
//     </Router>
//   );
// };








function Okta(){
  return (
    <div>OKTA Secure Route</div>
  )
}





function App() {
  const [{ user }] = useContext(AppStateContext);
  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };
  return (
    <>
        <Security restoreOriginalUri={restoreOriginalUri} oktaAuth={oktaAuth}>
        { user===null && !window.location.pathname.startsWith('/login/callback') ? <OktaLogin/> : null}
        <Layout>
            <Switch>
              {/* <SecureRoute path="/okta" exact={true} component={Okta} /> */}
              <Route path="/chart/:id" component={Chart} />
              <Route path="/charts/id/:id" component={Charts} />
              <Route path="/charts/:page?" component={Charts} />
              <Route path="/gotochart/:id" component={GotoChart} />
              <Route path='/login/callback' component={OktaLoginCallback} />
              <Route path="/login" component={Login} />
              <Route path="/logout" component={Logout} />
            </Switch>
        </Layout>
        </Security>
      <ToastContainer />
    </>
  );
}






export default App;
