import clsx from "clsx"
import { Link, NavLink, useLocation } from "react-router-dom"

/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ]
  }
  ```
*/
const tabs = [
    { name: 'Applied', href: '#', count: '52', current: false },
    { name: 'Phone Screening', href: '#', count: '6', current: false },
    { name: 'Interview', href: '#', count: '4', current: true },
    { name: 'Offer', href: '#', current: false },
    { name: 'Disqualified', href: '#', current: false },
  ]
  
  export default function Tabs({
      tabs, // [{ name: 'Applied', href: '#', count: '52', current: false },]
      baseUrl,
  }) {
    //   const location = useLocation();
    return (
      <div className="mb-2">
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            // defaultValue={tabs.find((tab) => tab.current).name}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((tab) => (
                tab.disableWhenZeroCount && !tab.count? 
                  <div key={tab.name} className={
                        'text-gray-400 cursor-text select-none border-transparent whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm'
                  }>{tab.name}</div>
                  :
                <NavLink
                  key={tab.name}
                  to={`${baseUrl}${tab.href}`}
                  activeClassName="border-indigo-500 text-indigo-600"
                  className={clsx(
                      "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200 whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                  )}
                  aria-current={tab.current ? 'page' : undefined}
                >
                  {tab.name}
                  {tab.count ? (
                    <span
                      className={clsx(
                        tab.current ? 'bg-indigo-100 text-indigo-600' : 'bg-gray-100 text-gray-900',
                        'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                      )}
                    >
                      {tab.count}
                    </span>
                  ) : null}
                </NavLink>
                
              ))}
            </nav>
          </div>
        </div>
      </div>
    )
  }
  