// import {  createContext, useContext, useEffect, useReducer } from 'react';
import {  createContext, useEffect, useReducer } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import OktaLogin from './OktaLogin';
// import { doc, getDoc } from 'firebase/firestore';
// import { firestore } from './firebase';

const initialState = {
    user: 0,
}

export const AppStateContext = createContext(initialState);

const reducer = (state,payload) => {
    return {...state, ...payload}
}
  
export default function ContextWrapper({children}) {
    const theReducer = useReducer(reducer, initialState);
    const [state, setState] = theReducer;
    useEffect(()=>{
      const auth = getAuth();
        //   console.log('auth from AppState.js is', auth.Provider);
      onAuthStateChanged(auth, user => setState({user}));
    },[]);

    useEffect(()=>{ // TEMP FOR DEBUGGING!
        console.log('USER from AppState.js is', state.user);
        window.firebaseUser = state.user;
    },[state.user]);

    return <AppStateContext.Provider value={theReducer}>{children}</AppStateContext.Provider>
}