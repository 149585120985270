import {  useEffect } from 'react';
import {  useHistory } from 'react-router-dom';
import OktaSignIn from '@okta/okta-signin-widget';
import { getAuth, signInWithCustomToken } from '@firebase/auth';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';

function OktaLoginCallback(){
    const history = useHistory()
    useEffect(()=>{
      (async ()=>{
        console.log('[LoginCallback]')
        const oktaSignIn = new OktaSignIn({
          baseUrl: "https://balancehealth.okta.com", //OKTA_ORG_URL,
          redirectUri: window.location.url,
          authParams: {
              display: 'page',
          },
        });
        let codeChallengeAndVerifier = window.sessionStorage.getItem('okta_codeChallengeAndVerifier')
        codeChallengeAndVerifier = codeChallengeAndVerifier?  JSON.parse(codeChallengeAndVerifier):{};
  
        
        console.log('oktaSignIn:', oktaSignIn)
        
        // console.log('window.location',Object.fromEntries((new URLSearchParams(window.location.search)).entries()));
        const code = Object.fromEntries((new URLSearchParams(window.location.search)).entries()).code;
  
        console.log('[CHALLENGE]', 
          JSON.stringify({...codeChallengeAndVerifier, code}, null, 2)
        )
  
        const cloudFunctionUrl = 'https://us-central1-emr-archive.cloudfunctions.net/app';
        let fbToken;
        try {
          console.log('About to fetch cloud function.', codeChallengeAndVerifier.codeVerifier)
          const payload = new URLSearchParams({
            code,
            codeVerifier: codeChallengeAndVerifier.codeVerifier,
          });
          const respFirebaseToken = await fetch(`${cloudFunctionUrl}/oktatofirebase?${payload}`)
          const { firebaseToken } = await respFirebaseToken.json();
          fbToken = firebaseToken;
        } catch (err) {
          console.log("FAILED getting Firebase token:", err);
        }
  
        console.log('Response from cloud function. fbToken:', fbToken)
  
  
        const auth = getAuth();
        signInWithCustomToken(auth, fbToken)
          .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            console.log('SIGNED IN with custom token!', user)
            const restoreUrl = window.localStorage.getItem('restore_url');
            window.localStorage.setItem('restore_url','');
            history.push(restoreUrl || '/');
          })
          .catch((error) => {
            console.log('OOOPS', error);
          });
        
  
  
  
        
        /*
        if (false) {
          console.log('[LoginCallback] hasTokensInUrl')
          // Get the access token from Okta.
          // const oktaTokenResponse = await oktaSignIn.authClient.token.parseFromUrl();
          // const accessToken = oktaTokenResponse.tokens.accessToken.value;
          console.log('[LoginCallback] okta token:', accessToken)
          
          // Use the access token to call the firebaseCustomToken endpoint.
          const firebaseTokenResponse = await fetch('https://us-central1-emr-archive.cloudfunctions.net/app/firebaseCustomToken', {
            headers: {
              'Authorization': `Bearer ${accessToken}`,
            }
          });
          const firebaseToken = await firebaseTokenResponse.text();
          console.log('[LoginCallback] firebase token:', firebaseToken)
      
          try {
            // await firebase.auth().signInWithCustomToken(firebaseToken);
            const auth = getAuth();
            const smth = await signInWithCustomToken(auth, firebaseToken)
            console.log("DONE signInWithCustomToken", smth)
          } catch (err) {
              console.error('Error signing in with custom token.', err);
          }
        }
        */
      })()
    },[]);
    return (
      <div>[LoginCallback]</div>
    )
  }
  
  export default OktaLoginCallback