import React, { useContext, useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import randomstring from 'crypto-random-string';
import CryptoJS from 'crypto-js';
// import { signOut } from "./firebase";
import { AppStateContext } from './AppState'


function OktaLogin({className, children}) {
  const { authState, oktaAuth } = useOktaAuth();
  // const [{ user }] = useContext(AppStateContext);
  const [codeChallengeAndVerifier, setCodeChallengeAndVerifier] = useState();
  console.log('authState from OktaLogin.js is',authState)

  useEffect(()=>{
    setCodeChallengeAndVerifier(generateCodeChallengeAndVerifier());
  },[]);

  useEffect(()=>{
    if(codeChallengeAndVerifier){
      loginWithRedirect();
    }
  },[codeChallengeAndVerifier]);

  
  
  const loginWithRedirect = () => {

    console.log("OktaLogin, signing in with Redirect. codeChallengeAndVerifier:", codeChallengeAndVerifier);

    window.localStorage.setItem('restore_url', window.location.pathname)
    
      // const tmp_signin_params = window.tmp_signin_params ?? {};
      const OktaLoginParams = { originalUri: "/okta", 
        pkce: true,
        clientId: '0oa2nozis9Tv3XMRy5d7',
        responseType: 'code',
        scope: 'openid',
        redirectUri: 'https://emrviewer.com/login/callback',
        nonce: 'def',
        codeChallengeMethod: 'S256',
        // responseMode: 'fragment',
        // responseMode: 'query',
        // codeChallenge: 'qjrzSW9gMiUgpUvqgEPE4_-8swvyCtfOVvg55o5S_es',
        // codeVerifier: 'M25iVXpKU3puUjFaYWg3T1NDTDQtcW1ROUY5YXlwalNoc0hhakxifmZHag'
        // ...tmp_signin_params
        codeChallenge: codeChallengeAndVerifier.codeChallenge,
        codeVerifier: codeChallengeAndVerifier.codeVerifier
        // state: 'abc',
      };
      console.log('OktaLogin params:',OktaLoginParams)

      // https://balancehealth.okta.com/oauth2/default/v1/authorize?client_id=0oa2nozis9Tv3XMRy5d7&response_type=code&scope=openid&redirect_uri=https://emrviewer.com/login/callback&state=abc&nonce=abc&response_mode=fragment&code_challenge=qjrzSW9gMiUgpUvqgEPE4_-8swvyCtfOVvg55o5S_es&code_challenge_method=S256
      // https://balancehealth.okta.com/oauth2/default/v1/authorize?
      //  client_id=0oa2nozis9Tv3XMRy5d7&
      //  response_type=code&
      //  scope=openid&
      //  redirect_uri=https://emrviewer.com/login/callback&state=abc&
      //  nonce=abc&
      //  response_mode=fragment&
      //  code_challenge=qjrzSW9gMiUgpUvqgEPE4_-8swvyCtfOVvg55o5S_es&
      //  code_challenge_method=S256
      
      
      oktaAuth.signInWithRedirect(OktaLoginParams);

  }

  return null;

  // const logOut = () => {
  //   signOut();
  //   oktaAuth.signOut();
  // }

  // const buttonText = user ? "Logout" : "Login";
  // const btnLogic = user ? logOut : loginWithRedirect;

  return (
      <button onClick={loginWithRedirect} className={className}>Login</button>
  );
}



function generateCodeChallengeAndVerifier(){

  if(window.location.pathname.startsWith('/login/callback')){
    return JSON.parse(window.sessionStorage.getItem('okta_codeChallengeAndVerifier'));
  }
  
  
  // Code verifier: Random URL-safe string with a minimum length of 43 characters
  // Code challenge: Base64 URL-encoded SHA-256 hash of the code verifier
  const codeVerifier = randomstring({
      length: 58,
      charset: 'alphabetic'
    });

  // const codeVerifier = 'M25iVXpKU3puUjFaYWg3T1NDTDQtcW1ROUY5YXlwalNoc0hhakxifmZHag';
  // const codeVerifier = 'g0f694593e15099447f7a18c5ad94fa72c3eb354bd8daa929e45b041';
  console.log('codeVerifier:', codeVerifier);
  const codeChallenge = generateCodeChallenge(codeVerifier);

  // const codeChallengeWorks = 'qjrzSW9gMiUgpUvqgEPE4_-8swvyCtfOVvg55o5S_es';

  console.log('generateCodeChallenge', codeChallenge);
  // console.log('Should be ',codeChallengeWorks )
  
  const codeChallengeAndVerifier = { codeChallenge, codeVerifier };
  window.sessionStorage.setItem('okta_codeChallengeAndVerifier', JSON.stringify(codeChallengeAndVerifier));
  console.log(JSON.stringify(codeChallengeAndVerifier,null, 2));
  return codeChallengeAndVerifier
}

function generateCodeChallenge(code_verifier) {
    return base64URL(CryptoJS.SHA256(code_verifier))
}
function base64URL(string) {
    return string.toString(CryptoJS.enc.Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
}





export default OktaLogin;