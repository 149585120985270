import { useOktaAuth } from "@okta/okta-react";
import { getAuth } from 'firebase/auth';
import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
export default function Logout() {
  const { authState, oktaAuth } = useOktaAuth();
    useEffect(()=>{
      const auth = getAuth();
      auth.signOut();
      oktaAuth.signOut();
    },[]);
    return <Redirect to="/login"/>
  }