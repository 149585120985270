import { useEffect, useMemo, useState } from "react";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { CloudDownloadIcon } from "@heroicons/react/outline";
import clsx from "clsx";

export default function Table({ data }) {
    const [checked, setChecked] = useState([]);
    const dataRows = useMemo(() => {
        if (!data) return [];
        return data.slice(1);
    }, [data]);
    const hasFileDownloads = useMemo( ()=> data ? !!data[0].find((cell)=>cell==="File Path")  : false);
    if (!data) return null;
    return (
        <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    {data[0].map((cell,i) => {
                                        return (
                                            <th
                                                key={i}
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                {cell}
                                            </th>

                                        )
                                    })}
                                    {hasFileDownloads?<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        <div className="underline text-blue-600 cursor-pointer" onClick={async ()=>{
                                            const els = document.querySelectorAll('.DownloadLink');
                                            for(let el of els){
                                                el.click();
                                                await new Promise(r=>setTimeout(r,1000));
                                            }
                                        }}>
                                            Download Selected
                                        </div>
                                        <span className="inline-block underline text-blue-600 cursor-pointer mt-2 pr-2 text-xs" onClick={()=>{
                                            setChecked(Array.from({length:dataRows.length}, (_,i)=>i));
                                        }}>
                                            Select All
                                        </span>
                                        <span className="inline-block underline text-blue-600 cursor-pointer text-xs" onClick={()=>{
                                            setChecked([])
                                        }}>
                                            Clear All
                                        </span>
                                    
                                    </th>:''}
                                </tr>
                            </thead>
                            <tbody>
                                {dataRows.map((row, i) => (
                                    <tr className={i % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                        {row.map((cell,j) => <Cell key={j} cell={cell} i={j} selected={checked.includes(i)} />)}
                                        {hasFileDownloads?
                                            <div className="px-6 py-4 text-sm text-gray-500 max-w-md">
                                                <input type="checkbox"
                                                    checked={checked.includes(i)}
                                                    onChange={e=>{
                                                        setChecked(checked => e.target.checked ? [...checked, i] : checked.filter(c => c!==i) )
                                                }} />
                                            </div>
                                        :''}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

function Cell({cell, i, selected}){
    const [url, setUrl]=useState(null);
    useEffect(()=>{
        if(cell && typeof cell==='object' && cell.filename) {
            const storage = getStorage();
            const pathReference = ref(storage, `chartdocs/${cell.filename}`);
            getDownloadURL(pathReference).then(url => {
                setUrl(url);
            }).catch(err=>console.log);
        }
    },[cell]);

    return <td key={i} className="px-6 py-4 text-sm text-gray-500 max-w-md">
        
        {
        typeof cell === 'string' || typeof cell === 'number' ?
         cell :
        (url ? <a href={url} className={clsx("text-indigo-600 hover:text-indigo-900 flex",
        selected? 'DownloadLink':''
        )}><CloudDownloadIcon className="w-6 h-6 mr-1"/> Download </a> :null)

    }</td>
}