import { collection, getDocs, query, where } from "firebase/firestore";
import { useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom"
import { toast } from "react-toastify";
import { AppStateContext } from "../AppState";
import { firestore } from "../firebase";

const GotoChart = () => {
    const {id} = useParams();
    const [{user}] = useContext(AppStateContext);
    const history = useHistory();
    useEffect(()=>{
        console.log('[GOTOCHART] user', user);

        if(user){
            console.log('GOTOCHART user', user.uid);
            console.log('GOTOCHART get charts by chart id', id);

            const chartsRef = collection(firestore, "chart");
            const q = query(chartsRef, where("id", "==", id));
            getDocs(q).then(snap => {
                console.log('Chart by id', id,'size:', snap.size);
                if(snap.size===0){
                    // no charts found by id
                    toast(`No charts found by this id: ${id}`);
                    history.push(`/charts`);
                } else if(snap.size===1){
                    // found exactly 1 chart by id
                    // redirect to the chart
                    const doc = snap.docs[0];
                    console.log('REDIRECT TO', doc.id, " => ", doc.data());
                    history.push(`/chart/${doc.id}/demographics`);
                } else {
                    history.push(`/charts/id/${id}`);
                    // snap.forEach(doc => {
                    //     console.log(doc.id, " => ", doc.data());
                    // })
                }
            }).catch(console.log);

        } else if(user!==0){
            // user is not logged in!
            history.push('/login')
        }
    },[user]);
    return (
        <div>
            <h3>Go to chart id {id}</h3>
        </div>
    )
}
export default GotoChart