// # https://blog.logrocket.com/implementing-okta-authentication-react-app/

// OKTA_DOMAIN=balancehealth.okta.com
// CLIENT_ID=Ooa2nozis9Tv3XMRy5d7
// CALLBACK_PATH='/login/callback'
// ISSUER='https://balancehealth.okta.com/oauth2/default'
// HOST='window.location.host'
// SCOPES='openid profile email'

// //maybe we add a config file?
// const CLIENT_ID = process.env.CLIENT_ID;
// const ISSUER = process.env.ISSUER

export default {
    clientId: '0oa2nozis9Tv3XMRy5d7',
    issuer: 'https://balancehealth.okta.com/oauth2/default',
    // redirectUri: 'http://localhost:3000/implicit/callback',
    // redirectUri: 'http://localhost:3000/login/callback',
    // redirectUri: 'https://us-central1-emr-archive.cloudfunctions.net/firebaseCustomToken',
    redirectUri: window.location.origin + '/login/callback',
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: false,
};

