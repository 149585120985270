// import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { AppStateContext } from "../AppState";
import { query, orderBy, limit, getDocs, collection, startAt, where } from "firebase/firestore";
import { firestore } from "../firebase";
import { Link, useHistory, useParams } from "react-router-dom";
import Pagination from "./Pagination";

/* This example requires Tailwind CSS v2.0+ */
const people = [
  { name: 'Jane Cooper', title: 'Regional Paradigm Technician', role: 'Admin', email: 'jane.cooper@example.com' },
  { name: 'Cody Fisher', title: 'Product Directives Officer', role: 'Owner', email: 'cody.fisher@example.com' },
  // More people...
]

let lastItemsPerPage = {
  current: {}
}; /* .current[1] = lastDoc */


export default function Charts() {
  const perPage = 10;
  const [{ user }] = useContext(AppStateContext);
  // const [page, setPage] = useState(1);
  const history = useHistory();
  const [maxPage, setMaxPage] = useState(1);
  // const [prevPageLastItem, setPrevPageLastItem] = useState(null);
  // const lastItemsPerPage = useRef({}); /* .current[1] = lastDoc */
  let { 
    id, // chart id to filter by
    page=1
  } = useParams();

  page = +page;

  const setPage = (p) => {
    let nextPage = p;
    if(typeof nextPage==='function'){
      nextPage = nextPage(page)
    }
    history.push(`/charts/${nextPage}`);
  }

  const fetchCharts = ({page = 1, id}) => {
    let q;
    console.log('[fetchCharts] REF', lastItemsPerPage.current, id)
    const prevPageLastItem = lastItemsPerPage.current?.[page-1];
    if( page > 1 && !prevPageLastItem){
      history.push('/charts');
    }
    console.log('GETTing page', page, 'last doc from prev page:', prevPageLastItem?.data())
    let queryParams = [ ];
    queryParams.push(collection(firestore, "chart") );
    if(id){
      console.log('get by id', id)
      queryParams.push(where('id','==', id))
    } else {
      queryParams.push(orderBy("scraped", 'desc'));
    }
    if (prevPageLastItem) {
      queryParams.push(startAt(prevPageLastItem));
      // q = query(
        //   collection(firestore, "charts"),
        //   orderBy("scraped", 'desc'),
        //   startAt(prevPageLastItem),
        //   limit(perPage)
        // );
    }

    queryParams.push(limit(perPage));
    q = query( ...queryParams );
    return getDocs(q);
    //  return fetch('/api/projects?page=' + page).then((res) => res.json())
  }

  const {
    // isLoading,
    // isError,
    // error,
    // isFetching,
    data,
    isPreviousData,
  // } = useQuery(['charts', page, id], () => fetchCharts({page,id}), {
  } = useQuery(['chart', page, id], () => fetchCharts({page,id}), {
    enabled: !!user?.uid, // && (!!lastItemsPerPage.current?.[page] || page===1),
    keepPreviousData: true
  });

  useEffect(()=>{
    let lastItem;
    console.log('Setting lastitem from page',page,'::', lastItem)
    if(data?.size && !isPreviousData) {
      lastItem = data.docs[data.docs.length - 1];
      console.log('ACTUALLY Setting lastitem from page',page,'::', lastItem)
      lastItemsPerPage.current[page] = lastItem;
      console.log('REF:', lastItemsPerPage.current);

      setMaxPage(maxPage => {
        return page > maxPage ? page : maxPage;
      })
      
    }
  },[data, isPreviousData, page]);


  const charts = useMemo(() => {
    let items = []
    if (data) {
      for (let doc of data.docs) {
        let data = doc.data();
        let item = {
          ...data,
          _id: doc.id,
          // unpacked: data.data ? JSON.parse(data.data) : null
          unpacked: {
            patientDemographics: data.data_patientDemographics ? JSON.parse(data.data_patientDemographics) : null
          }
        };
        items.push(item)
      }
    }
    return items;
  }, [data, page]);




  // console.log('charts', charts)

  return (
    <div className="flex flex-col mt-2">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">

        
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Date of Birth
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Gender
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    SSN
                  </th>
                </tr>
              </thead>
              <tbody>
                {charts?.map((chart, i) => {
                  let patientName = '(Unknown)';
                  const patient = chart?.unpacked?.patientDemographics?.patient;
                  if(patient?.['Patient Name']){ patientName = patient?.['Patient Name']; }
                  if(patient?.['First Name'] || patient?.['Last Name']){
                    patientName = `${patient?.['First Name']} ${patient?.['Last Name']}`.trim();
                  }

                  return (
                  <tr key={chart.id} className={i % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      <Link to={`/chart/${chart._id}/demographics`} className="text-indigo-600 hover:text-indigo-900">
                        {patientName}
                      </Link>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{chart?.unpacked?.patientDemographics?.patient?.['Date of Birth']}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{chart?.unpacked?.patientDemographics?.patient?.['Sex']}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{chart?.unpacked?.patientDemographics?.patient?.['SSN']}</td>
                  </tr>
                )})}
              </tbody>
            </table>
          </div>
        </div>
      </div>


      <Pagination 
      onNext={e => { setPage(page => page+1) }}
      onPrev={e => { setPage(page => page>1?page-1:1) }}
      setPage={page => { setPage(page) }}
      maxPage={maxPage}
      perPage={perPage}
      page={page}
      />

    </div>
  )
}
