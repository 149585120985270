import { initializeApp } from "firebase/app"
import { getFirestore } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { createContext, useEffect, useState } from "react";


const firebaseConfig = {
    apiKey: "AIzaSyBz1fLixiaimhoJGapj4LxS8roeOflnKA4",
    authDomain: "emr-archive.firebaseapp.com",
    projectId: "emr-archive",
    storageBucket: "emr-archive.appspot.com",
    messagingSenderId: "1038467230134",
    appId: "1:1038467230134:web:cb484c1ab8e8eac9b43917"
};

const firebaseApp = initializeApp(firebaseConfig);

export const firestore = getFirestore();





// export const AuthContext = createContext(null);

// export const Auth = (props) => {
//     const [user, setUser] = useState(null);
//     useEffect(()=>{
//         const auth = getAuth();
//         onAuthStateChanged(auth, setUser);
//     },[])
//     return (
//         <AuthContext.Provider value={user}>{props.children}</AuthContext.Provider>
//     )
// }

export const signOut = () => {
    const auth = getAuth();
    auth.signOut();
}


export default firebaseApp