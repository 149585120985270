import { useContext, useEffect } from "react"
import { getAuth, GoogleAuthProvider, signInWithRedirect } from "firebase/auth";
import { AppStateContext } from "./AppState";
import { useHistory } from "react-router-dom";

const provider = new GoogleAuthProvider();

export default function Login(){
    const [{user}] = useContext(AppStateContext);
    const history = useHistory();
    useEffect(()=>{
        console.log("user from Login.js is",user && user.provider);
        if(user) {
            history.push('/')
        }
    },[user])
    return (
        <button
        onClick={ e => {
            const auth = getAuth();
                signInWithRedirect(auth, provider);
            }
        }
        type="button"
        className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Sign In
      </button>
    );
}