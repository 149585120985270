// import { collection, doc, getDoc } from "firebase/firestore";
import { doc, getDoc } from "firebase/firestore";
import { useContext, useMemo } from "react";
import { useQuery } from "react-query";
import { Redirect, Route } from "react-router-dom";
import { AppStateContext } from "../AppState";
import { firestore } from "../firebase";
import Demographics from "./List";
import Table from "./Table";
import Tabs from "./Tabs";




export default function Chart({match:{params:{id}}}) {
    const [{ user }] = useContext(AppStateContext);

    // const { isLoading, error, data: docSnap, isFetching } = useQuery([`chart`, id], () => {
    const { data: docSnap } = useQuery([`chart`, id], () => {
        console.log('getting', id)
        // const docRef = doc(firestore, 'charts', id);
        const docRef = doc(firestore, 'chart', id);
        return getDoc(docRef);
        // .then(snap => {
        //     console.log('doc exists:', snap.exists())
        //     console.log('data', snap.data())
        // }).catch(err=> console.log)
    }, {enabled: !!user?.uid});

    // const data = useMemo(()=>{
    //     if(!docSnap) return null;
    //     const doc = docSnap.data();
    //     const scrapedData = doc.data? JSON.parse(doc.data): null;
    //     console.log('doc',doc, scrapedData);
    //     return {...doc, scrapedData, _id: docSnap.id}
    // },[docSnap]);

    const [dataByProperty, provider, docExists] = useMemo(()=>{
        if(!docSnap) return [null, null, 0 ];
        if(!docSnap.exists()) return [null, null, null ];
        
        const doc = docSnap.data();
        console.log("doc",doc)
        const docKeys = Object.keys(doc);
        let dataByProperty = {
            /*
                documents: [ [], [] ]
                medications: [ [], [] ]
            */
        };
        for(let docKey of docKeys){
            if(docKey.startsWith('data_')){
                const key = docKey.replace('data_','');
                dataByProperty[key] = JSON.parse(doc[docKey]);
            }
        }
        const provider = doc.provider;
        
        console.log('dataByProperty:', dataByProperty)
        
        return [dataByProperty, provider, true];
    },[docSnap]);
    

    if(docExists===null){
        return (
            <div>Document does not exist.</div>
        )
    }

    return (
        <div>
            {/* <div>Chart {id}</div> */}

            <Tabs
                baseUrl={`/chart/${id}/`}
                tabs={(()=>{

                    if(provider==='CPI') return [
                        { name: 'Demographics', href: 'demographics'},
                        { name: 'Insurance', href: 'insurance',
                            count: dataByProperty?.patientDemographics?.insurance ? 
                            dataByProperty.patientDemographics.insurance.length-1 : null,
                            disableWhenZeroCount: true,
                        },
                        { name: 'Documents', href: 'documents', 
                            count: dataByProperty?.documents ? dataByProperty.documents.length-1 : null,
                            disableWhenZeroCount: true,
                        },
                        { name: 'Ledger', href: 'ledger', 
                            count: dataByProperty?.ledger ? dataByProperty.ledger.length-1 : null,
                            disableWhenZeroCount: true,
                        },
                    ];

                    
                    return [
                        { name: 'Demographics', href: 'demographics'},
                        { name: 'Insurance', href: 'insurance', disableWhenZeroCount: true},
                        { name: 'Appointments', href: 'appointments', disableWhenZeroCount: true, 
                            count: dataByProperty?.appointments ? dataByProperty.appointments.length-1 : null
                        },
                        { name: 'Problems', href: 'problems', disableWhenZeroCount: true, 
                            count: dataByProperty?.problems ? dataByProperty.problems.length-1 : null
                        },
                        { name: 'Allergies', href: 'allergies', disableWhenZeroCount: true, 
                            count: dataByProperty?.allergies ? dataByProperty.allergies.length-1 : null
                        },
                        { name: 'Medications', href: 'medications', disableWhenZeroCount: true, 
                            count: dataByProperty?.medications ? dataByProperty.medications.length-1 : null
                        },
                        { name: 'Vitals', href: 'vitals', disableWhenZeroCount: true, 
                            count: dataByProperty?.vitals ? dataByProperty.vitals.length-1 : null
                        },
                        { name: 'Orders', href: 'orders', disableWhenZeroCount: true, 
                            count: dataByProperty?.orders ? dataByProperty.orders.length-1 : null
                        },
                        { name: 'Documents', href: 'documents', disableWhenZeroCount: true, 
                            count: dataByProperty?.documents ? dataByProperty.documents.length-1 : null
                        },
                        { name: 'Images', href: 'images', disableWhenZeroCount: true, 
                            count: dataByProperty?.images ? dataByProperty.images.length-1 : null
                        },
                    ];

                })()}
            />

            
            {/* <div>
                {isLoading? 'loading': JSON.stringify(data?.data())}
            </div> */}

            <Route path={`/chart/${id}/demographics`}>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                    { dataByProperty?.patientDemographics?.patient ? 
                        <Demographics data={dataByProperty.patientDemographics.patient}
                            title="Demographics"
                            subtitle="Patient Demographics"
                        />
                    : null}
                    { dataByProperty?.patientDemographics?.guarantor ? 
                        <Demographics data={dataByProperty.patientDemographics.guarantor}
                            title="Guarantor"
                            subtitle="Patient Guarantor"
                        />
                    : null}
                </div>
            </Route>
            {provider==='CPI'?
                <Route path={`/chart/${id}/insurance`}>
                    <Table data={dataByProperty?.patientDemographics?.insurance} />
                </Route>
                :<InsuranceRoute data={dataByProperty}/>
            }
            <Route exact path={`/chart/${id}`}>
                <Redirect to={`/chart/${id}/demographics`} />
            </Route>
            <Route path={`/chart/${id}/appointments`}>
                <Table data={dataByProperty?.appointments} />
            </Route>
            <Route path={`/chart/${id}/problems`}>
                <Table data={dataByProperty?.problems} />
            </Route>
            <Route path={`/chart/${id}/allergies`}>
                <Table data={dataByProperty?.allergies} />
            </Route>
            <Route path={`/chart/${id}/documents`}>
                <Table data={dataByProperty?.documents} />
            </Route>
            <Route path={`/chart/${id}/images`}>
                <Table data={dataByProperty?.images} />
            </Route>
            <Route path={`/chart/${id}/medications`}>
                <Table data={dataByProperty?.medications} />
            </Route>
            <Route path={`/chart/${id}/vitals`}>
                <Table data={dataByProperty?.vitals} />
            </Route>
            <Route path={`/chart/${id}/orders`}>
                <Table data={dataByProperty?.orders} />
            </Route>
            <Route path={`/chart/${id}/ledger`}>
                <Table data={dataByProperty?.ledger} />
            </Route>


            

            
            
        </div>
    )
}

function InsuranceRoute({data}){
    const insurances = useMemo(()=>{
        // data?.scrapedData?.patientDemographics?.insurance
        // data?.scrapedData?.patientDemographics?.insurancePrimary
        const insuranceRows = data?.patientDemographics?.insurance;
        const insuranceAddressRows = data?.patientDemographics?.insurancePrimary;
        return insuranceRows?.map((row,i)=>{
            if(!i) return;
            const insuranceCode = row[1];
            const insuranceAddress = insuranceAddressRows?.find(addrRow => addrRow[0]===insuranceCode);

            let data = {};
            insuranceRows[0].forEach((title,i)=>{
                data[title] = row[i];
            });
            insuranceAddress?.forEach((value,i)=>{
                data[insuranceAddressRows[0][i]] = value;
            });
            // console.log('data', data, insuranceAddress)
            return data;
            // return {...row, ...insuranceAddress};
        }).filter(v=>!!v)
    }, [data]);

    return (
        <Route path={`/chart/${data?._id}/insurance`}>
            
            
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                {insurances?.map((insurance,i) => {
                    return insurance ? 
                        <Demographics key={i} data={insurance}
                            title={i===0?'Primary':'Secondary'}
                            subtitle="Insurance"
                        />
                    : null
                })}
            </div>
        </Route>
    )
}



